import React, { useState, useEffect } from 'react';
import { Container, Col } from 'react-bootstrap';
import Masonry from 'react-masonry-component';

import { Section, Box, ListNav, ButtonOutline } from '../../components/Core';
import WorkCard from '../../components/WorkCard';
import { masonryWorks1 } from '../../data';

const Works = () => {
	const [items, setItems] = useState([]);
	const [activeLink, setActiveLink] = useState('*');

	useEffect(() => {
		setItems(masonryWorks1);
	}, []);

	const filterBy = (cat) => {
		if (cat === '*') {
			setActiveLink('*');
			setItems(masonryWorks1);
		} else {
			const filteredItems = masonryWorks1.filter((item) => {
				return item.categories.indexOf(cat) !== -1;
			});
			setActiveLink(cat);
			setItems(filteredItems);
		}
	};

	const masonryOptions = {
		transitionDuration: 1000,
	};

	return (
		<>
			{/* <!-- Works Area --> */}
			<Section className="position-relative">
				<Container>
					<Box mb="2.5rem" ml="-1.75rem">
						<ListNav className="nav">
							<li className="nav-item">
								<a
									className={`nav-link font-weight-bold text-uppercase ${
										activeLink === '*' ? 'active' : null
									}`}
									onClick={(e) => {
										e.preventDefault();
										filterBy('*');
									}}
								>
									All works
								</a>
							</li>
							<li className="nav-item">
								<a
									className={`nav-link font-weight-bold text-uppercase ${
										activeLink === 'branding' ? 'active' : null
									}`}
									onClick={(e) => {
										e.preventDefault();
										filterBy('branding');
									}}
								>
									Branding
								</a>
							</li>
							<li className="nav-item">
								<a
									className={`nav-link font-weight-bold text-uppercase ${
										activeLink === 'ux-design' ? 'active' : null
									}`}
									onClick={(e) => {
										e.preventDefault();
										filterBy('ux-design');
									}}
								>
									UX Design
								</a>
							</li>
							<li className="nav-item">
								<a
									className={`nav-link font-weight-bold text-uppercase ${
										activeLink === 'photography' ? 'active' : null
									}`}
									onClick={(e) => {
										e.preventDefault();
										filterBy('photography');
									}}
								>
									Photography
								</a>
							</li>
						</ListNav>
					</Box>
				</Container>

				<Container fluid>
					<Masonry
						options={masonryOptions}
						className={'masonry-grid row'} // default ''
					>
						{items.map((item, index) => (
							<Col lg="3" md="6" sm="12" key={index} className="filtr-item">
								<WorkCard workItem={item} mb="30px" link={item.link} />
							</Col>
						))}
					</Masonry>
					<Box pt="3rem" className="text-center">
						<ButtonOutline>Load more works</ButtonOutline>
					</Box>
				</Container>
			</Section>
		</>
	);
};

export default Works;
